export default {
  namespaced: true,
  state: {
    // current_menu: -1,
    current_cache_tab_list: ["home"],
    current_tab_list: [
      {
        path: "/",
        name: "home",
        title: "首页",
        id: -1,
      },
    ],
  },
  getters: {
    TabDate: (state) => state.TabDate,
  },
  mutations: {
    set_current_menu(state, value) {
      state.current_menu = value;
    },
    set_current_tab_list(state, value) {
      state.current_tab_list = value;
    },
    add_current_tab_list(state, value) {
      let found = false;
      for (let i = 0; i < state.current_tab_list.length; i++) {
        if (
          JSON.stringify(state.current_tab_list[i]) === JSON.stringify(value)
        ) {
          found = true;
          break;
        }
      }
      if (!found) {
        state.current_tab_list.push(value);
      }
    },
    del_current_tab_list(state, index) {
      state.current_tab_list.splice(index, 1);
    },
    set_current_cache_tab_list(state, value) {
      state.current_cache_tab_list = value;
    },
    add_current_cache_tab_list(state, value) {
      if (!state.current_cache_tab_list.includes(value)) {
        state.current_cache_tab_list.push(value);
      }
    },
    del_current_cache_tab_list(state, index) {
      state.current_cache_tab_list.splice(index, 1);
    },
  },
};
