export default {
    namespaced: true,
    state: {
        menuDate:[]
    },
    mutations: {
        changeIsmenuDate(state,payload) {
            state.menuDate = payload
        }
    }

}